.Noteful-form {
  background-color: #1d3656;
  color: #dedede;
  padding: 16px;
  max-width: 332px;
}

.Noteful-form label {
  display: block;
  font-size: 16px;
  padding-bottom: 8px;
}

.Noteful-form input,
.Noteful-form textarea,
.Noteful-form select {
  font-size: 14px;
  padding: 8px;
  width: 300px;
}

.Noteful-form select {
  height: 33px;
}

.Noteful-form > .field {
  margin-bottom: 16px;
}
.Noteful-form > .buttons {
  margin-top: 26px;
}

.Noteful-form button {
  background-color: #010d1e;
  border-radius: 4px;
  border: 0;
  color: #cacaca;
  display: block;
  font-size: 18px;
  font-weight: bold;
  padding: 8px;
  text-align: center;
  text-decoration: none;
}
.Noteful-form button[type="submit"]:hover {
  background-color: #29862e;
  color: white;
}
